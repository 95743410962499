<template>
  <section class="privacy">
    <h1 class="title">Політика конфіденційності</h1>
    <div class="container container--min">
      <article>
        <h4 class="hidden-text">Положення політики конфіденційності</h4>

        <p class="paragraph">
          Веб-сайт
          <a class="link" href="https://ntz.digital">https://ntz.digital</a>
          знаходиться під управлінням ТОВ «АЛОКА АС» (тут і далі – «Компанія» або «Ми»).
        </p>

        <p class="paragraph">
          Ця Політика Конфіденційності надається користувачам, отримувачам послуг в сфері надання послуг
          морської освіти (далі – «Учасники» або «Ви»), а також кінцевим споживачам (також – «Ви») послуг,
          які надаються користувачами інтернет-платформи, що забезпечує доступ до програмних продуктів та послуг,
          присвячених навчальному процесу в Навчальних тренажерних центрах в основному за рахунок обробки баз даних
          за допомогою Веб-сайту, (далі – «Веб-сайт»), що належить і управляється
          ТОВ «АЛОКА АС» (далі – «Компанія» або «Ми»).
        </p>

        <p class="paragraph">
          Наступні пункти детально описують наші дії щодо інформації, яку Ви надаєте нам.
          Наша Політика Конфіденційності ґрунтується на загальній практиці,
          Конституції України та Закону України "Про захист персональних даних" від 01.06.2010 р. №2297-VI,
          законом України «Про інформацію», постановами Кабінету Міністрів України «Про перелік відомостей,
          що не становлять комерційної таємниці» № 61 від 09.08.1993 р., Положення Цивільного та
          Господарського кодексів України, Загального регламенту про захист даних ЄС 2016/679
          (EU General Data Protection Regulation, далі «GDPR»). Мета нашої політики конфіденційності –
          проінформувати Вас про порядок збору, зберігання і обробки Персональних даних та інформації,
          що надається Вами у процесі користування цього веб-сайту.
        </p>

        <p class="paragraph">
          Ми контролюємо способи збору Ваших Персональних даних і визначаємо цілі, для яких Персональні дані
          використовуються Компанією. Ми є «Контролером даних» для цілей Загального регламенту про захист даних
          ЄС 2016/679 (EU General Data Protection Regulation, далі «GDPR») і іншого застосовного законодавства про
          захист даних, а також «Володільцем Персональних даних» в розумінні Закону України «Про захист
          Персональних даних» від 01.06.2010 р. № 2297-VI. в деяких випадках ми також є «Процесором даних»
          для цілей Генерального регламенту про захист даних ЄС 2016/679 (EU General Data Protection Regulation,
          далі «GDPR»), про такі випадки ми окремо зазначаємо в даній Політиці конфіденційності.
        </p>

        <p class="paragraph">
          Ми обробляємо Ваші Персональні дані лише в разі, якщо виконано одну з умов, зазначених у статті 6 GDPR
          та чинного законодавства України, в тому числі, але не виключно:
        </p>
        <ul>
          <li>
            Ви дали згоду на обробку Персональних даних;
          </li>
          <li>
            Обробка необхідна з метою укладання з Вами та/або виконання Договору про надання комплексу
            робіт/послуг з організації та проведення виставкового заходу, або надання Вам додаткових послуг;
          </li>
        </ul>

        <p class="paragraph">
          Ми можемо періодично оновлювати Політику конфіденційності, в тому числі, якщо цього вимагає застосовуване
          законодавство. Тому ми просимо Вас відвідувати веб-сайт
          <a class="link" href="#">https://biz.ntz.digital</a>,
          щоб переконатися в тому,
          що Ви маєте актуальну інформацію.
        </p>

        <p class="paragraph">
          Зверніть увагу, що Ви не можете використовувати веб-сайт доки не викажете Вашу явну згоду з положеннями
          цієї Політики Конфіденційності за допомогою технічних засобів, передбачених цим веб-сайтом.
        </p>
      </article>

      <article>
        <h3 class="subtitle">1. ОСНОВНІ ПОНЯТТЯ (ТЕРМІНИ)</h3>

        <p class="paragraph">
          1.1. Персональні дані – це будь-яка інформація персонального характеру, яка дозволяє третій особі
          ідентифікувати фізичну особу (суб'єкта даних).
        </p>

        <p class="paragraph">
          1.2. Фізична особа, що ідентифікується (Суб'єкт Персональних даних) – це фізична особа,
          яку можна прямо або опосередковано ідентифікувати, зокрема, за допомогою посилання на певний ідентифікатор
          (ім'я, прізвище, номер документа, інший ідентифікатор) або шляхом аналізу кількох елементів фізичної,
          генетичної, психологічної, економічної, культурної або іншої ідентичності даної фізичної особи.
        </p>

        <p class="paragraph">
          1.3. Користувачі – особи, які використовують Веб-сайт (и) Компанії.
        </p>

        <p class="paragraph">
          1.4. Веб-сайт – інтернет-сайт.
        </p>

        <p class="paragraph">
          1.5. Контролер Персональних даних – це фізична або юридична особа, яка визначає цілі та засоби для обробки
          Персональних даних та несе основну відповідальність за їхню обробку. Контролер Персональних даних
          є «Володільцем Персональних даних» в термінології українського законодавства.
        </p>

        <p class="paragraph">
          1.6. Процесор Персональних даних – це фізична або юридична особа, яка на підставі інструкцій
          (вказівок, розпоряджень) контролера обробляє персональні дані для контролера. Процесор Персональних
          даних є «Розпорядником Персональних даних» в термінології українського законодавства.
        </p>

        <p class="paragraph">
          1.7. Обробка Персональних даних – це будь-яка операція або сукупність операцій, які виконуються з
          Персональними даними або масивами Персональних даних з використанням або без використання автоматичних
          процедур, такі як збір, запис, систематизація, структурування, зберігання, зміна, впорядкування, перегляд,
          використання, поширення або будь-який інший вид надання доступу третім особам, в тому числі
          співробітникам контролера або процесора Персональних даних, а також видалення даних.
        </p>

        <p class="paragraph">
          1.8. Автоматизована обробка Персональних даних - це будь-яка операція або сукупність операцій,
          які виконуються з Персональними даними або масивами Персональних даних з використанням повністю або
          частково автоматизованих засобів: зберігання даних, виконання логічних та (або) арифметичних операцій
          із цими даними, їхню зміну, знищення, вибірку або поширення;
        </p>
      </article>

      <article>
        <h3 class="subtitle">2. ЗАКОНОДАВСТВО, ЩО РЕГУЛЮЄ ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ.</h3>

        <p class="paragraph">
          2.1. Обробка Ваших Персональних даних здійснюється відповідно до вимог чинного законодавства України,
          в тому числі Закону України «Про захист Персональних даних». Обробка Персональних даних клієнтів, які
          перебувають на території ЄС або є громадянами ЄС, регулюється, зокрема, Загальним регламентом про захист
          даних ЄС 2016/679 (далі – «GDPR»).
        </p>
      </article>

      <article>
        <h3 class="subtitle">3. СФЕРА ДІЇ ЦІЄЇ ПОЛІТИКИ.</h3>

        <p class="paragraph">
          3.1. Ця Політика поширюється на Персональні дані, що збираються нами за допомогою цього Веб-сайту
          Персональні дані, зокрема, але не виключно, означають дані, які ідентифікують особисто Вас,
          як окремо, так і в комбінації з іншою інформацією, доступною нам. Приклади Персональних даних
          включають в себе в тому числі Ваше ім'я, місцезнаходження, номер телефону, адреса електронної пошти,
          ІР-адреса та інші відомості, які Ви можете надавати, коли користуєтеся нашим Веб-сайтом.
        </p>

        <p class="paragraph">
          3.2. Використовуючи цей сайт, Ви приймаєте практики, описані в цій Політиці та надаєте свою пряму згоду
          на обробку Персональних даних шляхом проставлення у відповідному віконці в інтерфейсі і Веб-сайту
          «Я згоден» у формі маркування поля, що підтверджує прийняття цієї Політики. Якщо Ви не згодні
          з цією Політикою, просимо не використовувати цей Веб-сайт.
        </p>
      </article>

      <article>
        <h3 class="subtitle">4. ПЕРСОНАЛЬНІ ДАНІ, ЩО ЗБИРАЮТЬСЯ ЗА ДОПОМОГОЮ НАШОГО ВЕБ-САЙТУ.</h3>

        <p class="paragraph">
          4.1. Компанія збирає персональну інформацію про Вас або інших Користувачів при оформленні угод з
          будь-якими ліцензіатами Компанії, для використання Веб-сайту, відповідно до повноважень, наданих
          агентам Компанії, якщо такі є.
        </p>

        <p class="paragraph">
          4.2. Для цілей Політики Конфіденційності Компанії, Ваша персональна інформація поділяється на
          Персональну ідентифікаційну інформацію та Неперсоніфіковану ідентифікаційну інформацію.
        </p>

        <p class="paragraph">
          4.3. Персональна ідентифікаційна інформація (персональні дані) означає інформацію, яка дозволяє нам
          конкретно ідентифікувати Вас. Персональні відомості витребуються при замовленні послуг, виставленні
          рахунків, реєстрації облікового запису, тощо. Зокрема, але не виключно ці відомості можуть включати
          в себе Ваше ім'я, адресу (як юридичну, так і фактичну), адресу електронної пошти, IP-адресу, фінансову
          і персональну інформацію, інформацію про геолокацію і / або будь-яку іншу інформацію або будь-яку їх
          комбінацію, надану Вами.
        </p>

        <p class="paragraph">
          4.4. Неперсоніфікована ідентифікаційна інформація означає інформацію виключно статистичного характеру,
          яка сама по собі не ідентифікує конкретних фізичних або юридичних осіб, і яка залежить від відвідувань
          Вами Веб-сайту. Наприклад, ця інформація може включати в себе час доступу до Веб-сайту, а також адресу
          Веб-сайту, з якого Ви по посиланню перейшли безпосередньо на Веб-сайт, або за яким Ви залишили наш Веб-сайт,
          інформацію, що стосується пристроїв, які Ви використовуєте, та мереж, до яких Ви під'єднується,
          коли користуєтеся нашими сервісами: тип браузера та версія, тип та вид плагінів браузера, операційна
          система та платформа, інформація про Ваші відвідування, у тому числі, помилки завантаження, довжина
          відвідування певних сторінок, взаємодія сторінок або будь-який телефонний номер, що використовувався
          для дзвінка на номер нашого сервісного обслуговування клієнтів. Ця інформація використовується
          в зведеній формі для підвищення корисності і привабливості Веб-сайту та для Користувачів.
          Неперсоніфікована інформація в зведеній формі може передаватися , для покращення наших сервісів третім
          особам, з якими у нас укладено договірні відносини.
        </p>

        <p class="paragraph">
          4.5. <strong>Фінансова інформація та інші види інформації</strong>
        </p>
        <p class="paragraph">
          Ми можемо також, час від часу, збирати підкатегорію Персональних даних, яку ми називаємо Фінансовою
          Інформацією. Фінансова Інформація включає, номери кредитних / дебетових карт, банківські та інші номери
          рахунків. Зверніть увагу, що в деяких випадках, як описано в даній Політиці конфіденційності, поводження
          з Фінансовою Інформацією відрізняється від поводження з іншими видами Персональної Інформації.
        </p>

        <p class="paragraph">4.6. <strong>Щодо способів збирання інформації:</strong></p>
        <p class="paragraph">
          Персональна Інформація, як правило, може бути зібрана двома способами: (1) інформація, яка свідомо
          і добровільно вводиться у відповідні форми на Веб-сайті безпосередньо Вами у відповідь на певні
          підказки або вимоги заповнення поля при використанні або реєстрації в ньому, або іншим чином свідомо
          і добровільно надається Вами (наприклад, по електронній пошті); і (2) інформація, яка збирається
          автоматично при використанні Веб-сайту Компанії.
        </p>
      </article>

      <article>
        <h3 class="subtitle">5. ВИКОРИСТАННЯ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ ТА ЦІЛІ ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ.</h3>

        <p class="paragraph">
          5.1. Для виконання зобов'язань перед зареєстрованими Користувачами щодо забезпечення конфіденційності
          інформації з таких баз даних або про таких зареєстрованих Користувачів у будь-який час – за допомогою
          використання веб-ресурсів Компанії. Ми збираємо дані для визначених, чітких і законних цілей і надалі
          не опрацьовуємо їх у спосіб, що є несумісним з такими цілями. Ми збираємо дані відповідно до та з огляду
          на цілі опрацювання задля дотримання принципу мінімізації даних. Нам необхідні Ваші Персональні
          дані для того, щоб ми і наші Партнери могли забезпечити Вам доступ до наших сервісів, зокрема:
        </p>

        <p class="paragraph">
          5.1.1. для забезпечення належного підключення та надання інформації, що міститься в базах даних Веб-сайту за
          допомогою реєстраційних даних, пов'язаних з Вашим персональним акаунтом;
        </p>

        <p class="paragraph">
          5.1.2. для забезпечення належного підключення та надання інформації, що міститься в базах даних Веб-сайту
          за допомогою реєстраційних даних, пов'язаних з Вашим персональним акаунтом;
        </p>

        <p class="paragraph">5.1.3. безпеки та запобігання шахрайству;</p>

        <p class="paragraph">5.1.4. для підтвердження особи Користувача і оплати послуг Компанії;</p>

        <p class="paragraph">5.1.5. щоб перевірити Вашу особу, як Користувача;</p>

        <p class="paragraph">5.1.6. ефективного клієнтського обслуговування;</p>

        <p class="paragraph">5.1.7. персоналізації використання нашого Веб-сайту;</p>

        <p class="paragraph">5.1.8. надання Вам відомостей і повідомлень щодо використання Вами нашого Веб-сайту;</p>

        <p class="paragraph">
          5.1.9. для звернення до Вас поштою, телефоном (в тому числі SMS або іншими службами
          повідомлень) або електронною поштою, або шляхом показу відповідного повідомлення в межах Веб-сайту, що
          використовується Вами, коли це необхідно у зв'язку з використанням Вами Веб-сайту;
        </p>

        <p class="paragraph">5.1.10. вдосконалення контенту, функціоналу і зручності Веб-сайту;</p>

        <p class="paragraph">5.1.11. вдосконалення наших товарів і послуг;</p>

        <p class="paragraph">5.1.12. надання іншої інформації та послуг, запитуваних Вами;</p>

        <p class="paragraph">
          5.1.13. будь-яких інших цілей, зазначених у чинній Політиці конфіденційності або
          інших угодах між Компанією та Вами.
        </p>

        <p class="paragraph">
          5.2. Ми зберігаємо дані в формі, що дозволяє ідентифікацію суб'єктів даних не довше, ніж це є
          необхідним для цілей їхнього опрацювання та виконання нами договірних зобов'язань з таким суб'єктом даних.
          Принагідно інформуємо, що ми зберігаємо певну інформацію довше, аніж цього потребує ціль її опрацювання
          на виконання вимог закону, але не довше строку, передбаченого законодавством. Ми можемо здійснювати
          Автоматизовану обробку персональних даних. Така обробка здійснюється із дотриманням принципів та в
          межах цілей, визначених цією Політикою. Ми просимо Вас повідомляти лише ті персональні дані, які
          необхідні для надання обраної Вами послуги, отримання інформаційної розсилки або відповіді на Ваш
          спеціальний запит/претензію. Водночас якщо Ви вирішите повідомити нам додаткові персональні дані,
          ми також зможемо обробити їх із необхідним рівнем захисту.
        </p>
      </article>

      <article>
        <h3 class="subtitle">6. ЧАС ОБРОБЛЕННЯ (ЗБЕРІГАННЯ) ПЕРСОНАЛЬНИХ ДАНИХ</h3>

        <p class="paragraph">
          6.1. Ми не будемо зберігати Ваші дані довше, ніж це необхідно для виконання мети, для якої вони обробляються,
          або для дотримання встановлених законом вимог.
        </p>

        <p class="paragraph">
          6.2. Щоб визначити відповідний період зберігання, ми визначаємо характер і категорію Персональних даних,
          цілі, для яких ми їх обробляємо, і чи можемо ми досягти цих цілей за допомогою інших засобів.
        </p>

        <p class="paragraph">
          6.3. За загальним правилом, граничний час обробки Персональних даних, які використовуються в цілях
          податкового обліку в нашій Компанії становить 2555 днів з моменту подання податкової звітності за період,
          в якому відбулася відповідна операція. Зазначений час обробки відповідає положенням Податкового кодексу
          України щодо строків зберігання документів, пов'язаних з нарахуванням і сплатою податків та зборів.
        </p>
      </article>

      <article>
        <h3 class="subtitle">7. ФАЙЛИ COOKIES І АНАЛОГІЧНІ ТЕХНОЛОГІЇ</h3>

        <p class="paragraph">
          7.1. Ми використовуємо файли cookies та інші веб-технології для збору інформації та підтримки певних функцій
          наших Веб-сайтів. Наприклад, ми використовуємо ці технології для:
        </p>

        <p class="paragraph">
          7.1.1. збору інформації про способи використання наших Веб-сайтів відвідувачами на відвідуваних сторінках,
          на яких використовуються посиланнях і тривалості перебування на кожній сторінці;
        </p>

        <p class="paragraph">
          7.1.2. збору інформації про способи використання наших Веб-сайтів відвідувачами на відвідуваних сторінках,
          на яких використовуються посиланнях і тривалості перебування на кожній сторінці;
        </p>

        <p class="paragraph">
          7.1.3. підтримки функцій наших сайтів, наприклад, для усунення необхідності повторно вводити вхідні
          відомості, що вже знаходяться в нашій базі даних, або повторно ставити налаштування, вже задані при
          минулих відвідуваннях наших Веб-сайтів; для персоналізації роботи з нашими сайтами.
        </p>

        <p class="paragraph">
          7.2. Зазвичай інформація, яка збирається за допомогою зазначених веб-технологій, не дозволяє ідентифікувати
          Вас особисто. Проте, Вами був створений профіль користувача, наприклад, шляхом реєстрації в захищеному
          паролем розділі одного з наших Веб-сайтів, ми можемо пов'язати інформацію, зібрану нами за допомогою
          веб-технологій, з іншою інформацією, яка ідентифікує Вас особисто.
        </p>

        <p class="paragraph">
          7.3. Якщо Ви не хочете отримувати файли cookies, то Ви можете налаштувати у Вашому браузері обмеження або
          повну заборону щодо використання цих файлів. Крім того, Ви також можете видалити наші файли cookies після
          виходу з Веб-сайту. Хоча Ви не зобов'язані завантажувати наші файли cookies при відвідуванні наших
          Веб-сайтів, але якщо Ви налаштували обмеження щодо використання або повну заборону файлів cookies в
          Вашому браузері, Ви не зможете користуватися всіма функціями наших Веб-сайтів.
        </p>
      </article>

      <article>
        <h3 class="subtitle">8. НАДАННЯ КОМПАНІЄЮ ДОСТУПУ ДО ВАШОЇ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</h3>

        <p class="paragraph">
          8.1. Всередині Компанії. Ми надаємо спільний доступ до персональної інформації, яка збирається за
          допомогою нашого Веб-сайту працівникам Компанії – фізичним особам.
        </p>

        <p class="paragraph">
          8.2. Перехід контролю. Ваша персональна інформація може передаватися компаніям, які придбали корпоративні
          права або активи Компанії, або один з наших бізнесів, наприклад, в результаті продажу, злиття,
          реорганізації або ліквідації. У разі такого переходу контролю, Компанія користується Вашою Персональною
          інформацією з урахуванням реальної політики та заявлених нам Ваших вимог щодо конфіденційності.
        </p>

        <p class="paragraph">
          8.3. Нормативно-правова відповідність, захист наших прав і прав інших осіб. Ми маємо право розголошувати
          персональні дані, коли ми сумлінно вважаємо, що розголошення відповідає законодавству, згідно з постановою
          суду або рішення іншого правоохоронного органу. Ми також вправі розголошувати Персональну інформацію для
          запобігання або розслідування можливих злочинів, включаючи шахрайство або крадіжки Персональних даних,
          для примусового виконання або застосування наших умов користування онлайновими ресурсами або інших угод,
          а також для захисту своїх власних прав або майна, або прав власності, або безпеки наших Користувачів чи
          інших осіб.
        </p>

        <p class="paragraph">
          8.4. Сукупна інформація. Ми маємо право надавати обмежений доступ до демографічної або опитувальної
          інформації третім особам, однак така інформація матиме анонімну форму і не містить ніяких особистих
          даних і відомостей. Сукупна інформація, до якої ми надаємо обмежений доступ, може містити анонімну
          інформацію, яка збирається за допомогою використання файлів cookies і інших аналогічних технологій
          відстеження.
        </p>

        <p class="paragraph">
          8.5. За винятком випадків, передбачених цією статтею, ми не будемо обмінюватися Персональною або
          Фінансовою Інформацією або унікальними ідентифікаторами з третіми особами, за винятком наших агентів
          і ліцензіатів, без Вашої прямої попередньої згоди або законодавчої вимоги імперативного характеру.
          Компанія не продає імена, адреси електронної пошти або будь-яку іншу персональну і контактну інформацію
          третім особам, і ми не маємо жодного наміру робити це в майбутньому.
        </p>

        <p class="paragraph">
          8.6. Передача інформації нашим партнерам здійснюється на підставі договорів, положеннями яких передбачено
          цілі та способи обробки даних та закріплено зобов'язання партнерів щодо дотримання належного рівня захисту
          Персональних даних та їх обробку відповідно до чинного законодавства.
        </p>
      </article>

      <article>
        <h3 class="subtitle">9. ПОРЯДОК РОБОТИ З ЗАПИТАМИ СУБ'ЄКТА ПЕРСОНАЛЬНИХ ДАНИХ.</h3>

        <p class="paragraph">
          9.1. Ви як суб'єкт Персональних даних маєте право на одержання будь-яких відомостей про себе під час нашої
          обробки Ваших Персональних даних.
        </p>

        <p class="paragraph">
          9.2. Доступ суб'єкта Персональних даних щодо своїх даних здійснюється безоплатно.
        </p>

        <p class="paragraph">
          9.3. З метою отримання доступу до своїх даних, Ви можете звернутись із запитом до нас.
        </p>

        <p class="paragraph">У запиті зазначаються:</p>

        <p class="paragraph">
          9.3.1 прізвище, ім'я та по батькові, місце проживання (місце перебування) і реквізити документа,
          що посвідчує особу суб'єкта Персональних даних;
        </p>

        <p class="paragraph">
          9.3.2. відомості про базу Персональних даних, стосовно якої подається запит;
        </p>

        <p class="paragraph">
          9.4. Строк вивчення запиту на предмет його задоволення та надання відповіді не може перевищувати тридцяти
          календарних днів з дня його надходження.
        </p>

        <p class="paragraph">
          9.5. Протягом цього строку Ми надаємо відповідь на Ваш запит або, у тих випадках, коли Ми є Процесорами
          Персональних даних, Ми передаємо Ваш запит Володільцю бази Персональних даних та інформуємо Вас відповідно.
          у тих випадках, коли запит не може бути задоволений на підставі норм закону, Ми доводимо Вас до відома із
          зазначенням підстави, визначеної у відповідному нормативно-правовому акті.
        </p>
      </article>

      <article>
        <h3 class="subtitle">
          10.1. Права суб'єктів Персональних даних відповідно до законодавства України:
        </h3>

        <p class="paragraph">
          10.1.1. знати про джерела збору, місцезнаходження своїх Персональних даних, цілі їхньої обробки, місцезнаходження або місце проживання (перебування) Володільця або Розпорядника Персональних даних або дати відповідне доручення про отримання цієї інформації уповноваженим ними особам, окрім випадків, встановлених законом;
        </p>

        <p class="paragraph">
          10.1.2. отримувати інформацію про умови надання доступу до Персональних даних, включно із інформацією про третіх осіб, яким надаються їхні персональні дані;
        </p>

        <p class="paragraph">10.1.3. на доступ до своїх Персональних даних;</p>

        <p class="paragraph">
          10.1.4. отримувати не пізніше ніж за тридцять календарних днів із дня надходження запиту, окрім випадків, передбачених законодавством, відповідь про те, чи оброблюються його персональні дані, а також отримувати зміст таких Персональних даних;
        </p>

        <p class="paragraph">
          10.1.5. пред'являти мотивовану вимогу Володільцю Персональних даних із запереченням проти обробки своїх Персональних даних;
        </p>

        <p class="paragraph">
          10.1.6. пред'являти мотивовану вимогу про зміну або знищення своїх Персональних даних будь-яким Володільцем і Розпорядником Персональних даних, якщо ці дані обробляються незаконно або є неправдивими;
        </p>

        <p class="paragraph">
          10.1.7. на захист своїх Персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є неправдивими чи порочать честь, гідність та ділову репутацію фізичної особи;
        </p>

        <p class="paragraph">
          10.1.8. звертатися зі скаргами на обробку своїх Персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду;
        </p>

        <p class="paragraph">
          10.1.9. застосовувати засоби правового захисту в разі порушення законодавства про захист Персональних даних;
        </p>

        <p class="paragraph">
          10.1.10. вносити застереження про обмеження права на обробку своїх Персональних даних при наданні згоди;
        </p>

        <p class="paragraph">
          10.1.11. відкликати згоду на обробку Персональних даних;
        </p>

        <p class="paragraph">
          10.1.12. знати механізм автоматичної обробки Персональних даних;
        </p>

        <p class="paragraph">
          10.1.13. на захист від автоматизованого рішення, яке має для них правові наслідки.
        </p>

        <p class="paragraph">
          10.2. Інші права суб'єктів Персональних даних відповідно до GDPR:
        </p>

        <p class="paragraph">
          10.2.1. Право на інформацію;
        </p>

        <p class="paragraph">
          10.2.2. Право на виправлення даних про Вас;
        </p>

        <p class="paragraph">
          10.2.3. Відкликання згоди на обробку Персональних даних і право бути забутим.
        </p>
      </article>

      <article>
        <h3 class="subtitle">11. ЗАХИСТ КОМПАНІЄЮ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</h3>

        <p class="paragraph">
          11.1. Ми користуємося комплексною програмою безпеки даних, що містить комерційно обґрунтовані
          адміністративні, фізичні і технологічні заходи безпеки, для захисту Ваших Персональних даних від
          несанкціонованого доступу, використання, несанкціонованого або випадкового руйнування, зміни або розкриття.
          Хоча ми приймаємо комерційно обґрунтовані запобіжні заходи для забезпечення рівня безпеки, ми не можемо
          гарантувати абсолютну безпеку інформації, що надається через інтернет або зберігається в наших базах даних
          від злочинного втручання третіх осіб (хакерські атаки).
        </p>

        <p class="paragraph">
          11.2. Як контролер даних ми вживаємо відповідних технічних і організаційних заходів для гарантування того,
          що за замовчуванням опрацьовуються лише ті Персональні дані, які є необхідними для кожної спеціальної цілі
          опрацювання.
        </p>

        <p class="paragraph">
          11.3. Контролер даних не несе відповідальність за всі дії своїх Користувачів, а також Контролер даних (i)
          докладає розумних з комерційної точки зору зусиль для запобігання неуповноваженому доступу до Веб-сайту або
          їх використанню та оперативно повідомляє Клієнтів про будь-який такий неуповноважений доступ чи використання;
          та (ii) дотримується всіх чинних норм законодавства місцевого рівня та міжнародного рівня.
        </p>
      </article>

      <article>
        <h3 class="subtitle">12. ПОВНОВАЖЕННЯ ТА ВІДПОВІДАЛЬНІСТЬ ПРОЦЕСОРА ДАНИХ</h3>

        <p class="paragraph">
          12.1. На підставі договірних або законодавчих вимог, Ми можемо виступати в ролі
          Процесора Персональних даних.
        </p>

        <p class="paragraph">
          12.2. Якщо Персональні дані надходять від надавача послуг який користується цим Веб-сайтом, стосовно третіх
          осіб (кінцевих споживачів послуг, клієнтів, співробітників надавача послуг), Ми презюмуємо, що така
          інформація отримана надавачем послуг законним шляхом з отриманням відповідної згоди суб'єкта даних та не
          несемо відповідальності за те, яким чином була отримана така інформація.
        </p>
      </article>

      <article>
        <h3 class="subtitle">13. ДІТИ</h3>

        <p class="paragraph">
          13.1. Наш Веб-сайт призначений для використання особами віком від 16 років.
        </p>

        <p class="paragraph">
          13.2. Особи, що досягли 16-річного віку можуть використовувати наш Веб-сайт за умови надання згоди на
          обробку Персональних даних.
        </p>

        <p class="paragraph">
          13.3. У випадках виконання своїх договірних зобов'язань перед нашими Користувачами та клієнтами Ми можемо
          обробляти Персональні дані осіб, що не досягли 16-річного віку, за умови надання цих даних законними
          представниками таких осіб задля задоволення потреб і інтересів цих осіб, як суб'єктів персональних даних.
          Ми вважаємо, що Ви є законним представником особи, яка не досягла 16-річного віку, якщо Ви дієте в
          інтересах такої особи та погодились з умовами цієї Політики при використанні Веб-сайту. Якщо нам стане
          відомо, що Ви не є законним представником, Ми докладемо розумних зусиль з тим, щоб видалити Персональну
          інформацію щодо особи, яка не досягла 16-річного віку з наших баз даних.
        </p>
      </article>

      <article>
        <h3 class="subtitle">14. КОНТАКТНІ ДАНІ З ПИТАНЬ, ПОВ'ЯЗАНИХ З ПОЛІТИКОЮ КОНФІДЕНЦІЙНОСТІ</h3>

        <p class="paragraph">
          14.1. У разі виникнення питань щодо даної Політики або наших практик конфіденційності, надішліть електронного листа або письмове повідомлення (див. Контактні дані на цій сторінці).
        </p>

        <p class="paragraph">ТОВ «АЛОКА АС»</p>

        <p class="paragraph">Україна, Миколаїв</p>

        <p class="paragraph">54000, м. Миколаїв, вул. Чкалова (Велика Корениха), 37/1</p>

        <p class="paragraph">Слюсаренко Ольга Вікторівна,</p>

        <p class="paragraph">Ел. пошта: info@ntz.digital</p>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss" scoped>
@import "../styles/core/mixins";

  $privacy: "privacy";
  .#{$privacy} {
    padding: 60px 0;

    article {
      margin: 0 0 32px 0;
      &:last-child {
        margin: 0;
      }
    }

    ul {
      margin-bottom: 16px;
    }

    li {
      list-style: disc;
      font-size: 20px;
      line-height: 1.55;
      font-weight: 300;
      margin-left: 16px;
    }
  }
</style>